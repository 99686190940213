import React from "react"
import Layout from "../components/layout"
import Styles from "./css/policies.module.css"
import { Helmet } from "react-helmet"
const PrivacyPolicy = () => (
  <Layout>
    <Helmet>
      <title>Privacy Policy - Colorful Phone Cases</title>
      <meta name="description" content="Privacy policy for colorful phone cases" />
    </Helmet>
    <div className={Styles.wrapper}>
      <div className={Styles.mainDiv}>
        <h1 className={Styles.pageHeader}>Privacy Policy</h1>
        <p>This Privacy Policy describes how Colorfulphonecases.net and Colorfulphonecases-checkout.myshopify.com (the “Site” or “we”) collects, uses, and discloses your Personal Information when you visit or make a purchase from the Site.</p>
        <h2 className={Styles.miniHeader}>Collecting Personal Information</h2>
        <p>When you visit the Site, we collect certain information about your device, your interaction with the Site, and information necessary to process your purchases. We may also collect additional information if you contact us for customer support. In this Privacy Policy, we refer to any information that can uniquely identify an individual (including the information below) as “Personal Information”. See the list below for more information about what Personal Information we collect and why.</p>
        <h2 className={Styles.miniHeader}>Device information</h2>
        <p> Examples of Personal Information collected: version of web browser, IP address, time zone, cookie information, what sites or products you view, search terms, and how you interact with the Site.
          Purpose of collection: to load the Site accurately for you, and to perform analytics on Site usage to optimize our Site.
          Source of collection: Collected automatically when you access our Site using cookies.
          Disclosure for a business purpose: shared with our processor Shopify</p>
        <h2 className={Styles.miniHeader}>Order information</h2>
        <p>Examples of Personal Information collected: name, billing address, shipping address, payment information, email address, and phone number, though colorfulphonecases itself does not handle or store any payment information.
          Purpose of collection: to provide products or services to you to fulfill our contract, to process your payment information, arrange for shipping, and provide you with invoices and/or order confirmations, communicate with you, screen our orders for potential risk or fraud, and when in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.
          Source of collection: collected from you.
          Disclosure for a business purpose: shared with our processor Shopify</p>
      </div></div>
  </Layout>
)

export default PrivacyPolicy